<template>
  <div id="userInfo">
    <v-toolbar flat>
      <v-toolbar-title>
      <v-btn icon @click="navigationBack()" class="mr-1"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <template v-if="viewMode == 'edit'">{{defaultData.name}}</template>
      </v-toolbar-title>
      <v-spacer />
      <!-- 確認用 Password reset -->
      <confirm-dialog v-if="viewMode === 'edit'" type="info" width="500" btnTitle="送信" @accept="resetPassword">
        <template v-slot:title>パスワード再発行</template>
        <template v-slot:message>パスワード設定リンクを記載したメールを送信します。<br>※リンクの有効期限は60分です。</template>
        <template v-slot:activator="{ on }">
        <v-btn text @click="on"><v-icon>mdi-refresh</v-icon>パスワード再発行</v-btn>
        </template>
      </confirm-dialog>
    </v-toolbar>

    <v-container>
      <v-card :loading="isLoading">
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
          <v-row>
            <v-col cols="12" md="3" align="center" class="py-5">
              <v-avatar size="120" class="elavation-4 ma-6">
                <img v-if="user && user.photo" :src="user.photo.file_url" style="object-fit: cover; object-position: center;">
                <img v-else src="@/assets/ico/ico_no-user-photo.svg" style="object-fit: cover; object-position: center;">
                <v-btn :loading="isSelecting" @click="onButtonClick" elevation="1" absolute :class="['px-0',{'d-none': user.photo,}]" color="white">
                  <v-icon small>mdi-camera-flip</v-icon>{{ buttonText }}
                </v-btn>
                <input ref="uploader" class="d-none" type="file" accept="image/*" @change="onFileChanged">
              </v-avatar>
              <v-row v-if="viewMode === 'edit'">
                <v-col>
                  <h5 v-if="user.last_login_at">最終ログイン日</h5>
                  {{ user.last_login_at | moment("YYYY/MM/DD HH:mm") }}<br>
                  <h5 class="mt-4" v-if="user.create_at">登録日</h5>
                  {{ user.create_at | moment("YYYY/MM/DD HH:mm") }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="9" class="py-6">
              <v-row dense>
                <v-col cols="12" md="6"><v-text-field class="required" label="名前" v-model="user.name" :rules="[inputRules.required]"></v-text-field></v-col>
                <v-col cols="12" md="6"><v-text-field class="required" label="フリガナ" v-model="user.kana" :rules="[inputRules.required]"></v-text-field></v-col>
                <v-col cols="12" md="6"><v-text-field class="required" label="メールアドレス" v-model="user.email" :rules="[inputRules.required, inputRules.email]" prepend-icon="mdi-email"></v-text-field></v-col>
                <v-col cols="12" md="6"><v-text-field class="" label="電話番号" v-model="user.phone" prepend-icon="mdi-cellphone"></v-text-field></v-col>
                <v-col cols="12" md="6" v-if="viewMode === 'new'"><v-text-field class="required" label="パスワード" v-model="user.password" :rules="[inputRules.required, inputRules.password]" prepend-icon="mdi-lock" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" counter="72" append-outer-icon="mdi-reload" @click:append-outer="generatePassword" autocomplete="new-password"></v-text-field></v-col>
              </v-row>
            </v-col>
          </v-row>
          </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="navigationBack()">キャンセル</v-btn>
          <v-btn v-if="viewMode === 'edit'" left color="primary" @click="updateUser" :disabled="isChange"><v-icon>mdi-check</v-icon>変更を保存</v-btn>
          <v-btn v-promise-btn v-else-if="viewMode === 'new'" left color="primary" @click="addUser" :disabled="!isFormValid"><v-icon>mdi-check</v-icon>登録</v-btn>
          <v-spacer></v-spacer>

          <!-- 確認用 オリジナルダイアログ -->
          <confirm-dialog v-if="viewMode === 'edit'" btnTitle = "削除" @accept="deleteUser">
            <template v-slot:title>このアカウントを削除しますか？</template>
            <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
            <template v-slot:activator="{ on }">
             <v-btn icon @click="on"><v-icon>mdi-delete-forever</v-icon></v-btn>
            </template>
          </confirm-dialog>

      </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import axios from '@/plugins/axios'

export default {
  components: { ConfirmDialog },
  name: 'account',
  data: function () {
    return {
      viewMode: '',
      isLoading: false,
      // deleteDialog: false,
      isFormValid: false,
      showPassword: false,
      form: {
        file: null
      },
      user: {},
      defaultData: {},
      isSelecting: false,
      defaultButtonText: '変更',
      validMail: ''
    }
  },
  methods: {
    onButtonClick: function () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged: function (e) {
      this.form.file = e.target.files[0]
      this.defaultData.photo = this.form.file.name
    },
    readDataFromAPI: function () {
      this.isLoading = true
      const uid = Number(this.$route.params.id)
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login/${uid}`)
        .then(res => {
          this.defaultData = JSON.parse(JSON.stringify(res.data)) // save load data
          this.user = res.data
          this.validMail = res.data.email // valid loaded e-mail
          this.isLoading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    navigationBack: function () {
      this.$router.go(-1)
    },
    generatePassword: function () {
      this.showPassword = false
      this.user.password = this.generateUserPassword()
      this.showPassword = true
    },
    addUser: function () {
      console.info('add new user login')
      const _this = this
      if (_this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('_method', 'POST')
        formData.append('name', _this.user.name)
        formData.append('kana', _this.user.kana)
        formData.append('email', _this.user.email)
        formData.append('phone', _this.user.phone)
        formData.append('password', _this.user.password)
        if (_this.form.file) {
          formData.append('files[]', _this.form.file, _this.form.file.name)
        }
        _this.isLoading = true
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login`, formData)
            .then(res => {
              console.log(res.data)
              _this.$store.commit('setFlashSuccess', 'ユーザーが作成されました。')
              _this.isLoading = false
              resolve(res)
              _this.navigationBack()
            })
            .catch(err => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              _this.isLoading = false
              console.log(err)
              reject(err)
              _this.navigationBack()
            })
        })
      }
    },
    updateUser: function () {
      console.info('data update task')
      const _this = this
      const id = Number(_this.$route.params.id)
      if (_this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('name', _this.user.name)
        formData.append('kana', _this.user.kana)
        formData.append('email', _this.user.email)
        formData.append('phone', _this.user.phone)
        if (_this.form.file) {
          formData.append('files[]', _this.form.file, _this.form.file.name)
        }
        _this.isLoading = true
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login/${id}`, formData)
          .then(res => {
            console.log(res.data)
            _this.user = res.data
            _this.validMail = res.data.email
            _this.defaultData = JSON.parse(JSON.stringify(_this.user)) // update default data
            _this.$store.commit('setFlashSuccess', 'ユーザー情報を更新しました。')
            _this.isLoading = false
            _this.close()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
            _this.isLoading = false
            console.log(err)
            _this.close()
          })
      }
    },
    deleteUser: function () {
      const _this = this
      const id = Number(_this.$route.params.id)
      _this.isLoading = true
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login/${id}`)
        .then(res => {
          _this.$store.commit('setFlashSuccess', 'ユーザーを削除しました。')
          _this.isLoading = false
          _this.navigationBack()
        })
        .catch(err => {
          _this.$store.commit('setFlashError', 'ユーザーを削除することができませんでした。')
          _this.isLoading = false
          console.log(err)
        })
    },
    resetPassword: function () {
      console.log('reset password')
      this.userPasswordReset(this.validMail)
    },
    close: function () {
      this.$emit('close')
    }
  },
  computed: {
    buttonText: function () {
      return this.form.file ? this.form.file.name : this.defaultButtonText
    },
    isChange: function () {
      return JSON.stringify(this.user) === JSON.stringify(this.defaultData)
    }
  },
  created: function () {
    if (this.$route.params.id) {
      this.readDataFromAPI()
      this.viewMode = 'edit'
      console.info('Mode: edit')
    } else {
      this.viewMode = 'new'
      console.info('Mode: new')
    }
  }
}
</script>

<style scoped lang="scss">
  .v-avatar{
    button{
      // display: none;
      margin: auto;
    }
    &:hover{
      button{
        display: inline-flex!important;
      }
    }
  }
</style>
